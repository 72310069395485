import { gql } from '@apollo/client';
import React, { useState } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Divider, Image, Pagination } from 'semantic-ui-react';
import './App.css';
import RecordSearch from './RecordSearch';

const PAGE_SIZE = 10

const STORIES = gql`
query ScrollStories($first: Int, $limit: Int) {
  observation(criteria: {limit:$limit, first: $first, contentType:"image/*", anchorRel: "CARD"}) {
    observations {
      id
      title
      anchor
    }
    countReturned
    countAvailable
  }
}
`;

const CardScroll = props => {
  const [observations, setObservations] = useState(null)
  const [page, setPage] = useState(1)

  const totalPages = observations ? Math.max(1, Math.ceil(observations.countAvailable / PAGE_SIZE)) : 0;

  return (<div style={{textAlign: 'center'}}>
         <RecordSearch 
            filters={{contentType:"image/*"}} 
            variables={{storiesOnly: true}}
            client={props.client} 
            page={page} pageSize={PAGE_SIZE}
            onUpdate={(o, d) => {
              setObservations(o); 
              document.getElementById('main').scrollTo(0,0);
              if (d) {
                setPage(1)
              }
            }} 
            />

        {observations?.observations?.map(o => (
            <div>
              <Image 
                size='big'
                src={`${process.env.REACT_APP_REST_URI}/record/${o.id}/content`} 
                alt={`${o.title}`}
                href={`/report/${o.anchor}`}
                style={{}}
                />
                <Divider horizontal />
            </div>
            )
        )}
        <br/>
        <Pagination activePage={page} totalPages={totalPages} onPageChange={(e,d) => setPage(d.activePage)} />
      </div>)
}

export default CardScroll;