import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Divider, Form, Message } from 'semantic-ui-react';
import Validate from './Validate';
import Hideable from './Hideable';

const REGISTER = gql`
mutation Register($fullName:String, $preferredName:String, $email:String!, $password:String!) {
  register(input: {fullName:$fullName,preferredName:$preferredName,email:$email}, password: $password) {
    created
  }
}`
;

const Register = props => {
  const [values, setValues] = useState({fullName: '', preferredName: '', email: '', password: ''});
  const [error, setError] = useState(null);
  let navigate = useNavigate();

  const handleChange = e => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }

  document.title = `navscout - Register`;

  return <Container>
      <h3>Let's get started.</h3>
      In order to monitor your travel, you will need to create a free account.<br/>
      Enter your email address and a password so that you will be able to login.<br/>
      You can optionally provide your name so that emails will be addressed to you.
      <Divider horizontal />
      <Hideable hidden={error === null}>
        <Message error>Unable to register with the provided account details</Message>
      </Hideable>
      <Form fluid="true" onSubmit={e => {
          e.preventDefault();
          register(props.client, values, navigate, setError);
        }} >
        <Form.Field>
          <label>Email</label>
          <input name='email' placeholder='Email' value={values.email} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <label>Password</label>
          <input type='password' name='password' placeholder='Password' value={values.password} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <label>Full Name</label>
          <input name='fullName' placeholder='Full Name (Optional)' value={values.fullName} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <label>Preferred Name</label>
          <input name='preferredName' placeholder='Preferred Name (Optional)' value={values.preferredName} onChange={handleChange} />
        </Form.Field>
        <Container textAlign='center'>
          <Button className='nsbutton'
              disabled={!Validate.notBlank(values.email, values.password)}
              style={{margin:'0 auto 0 auto'}}
              primary="true"
              >Register</Button>
        </Container>
      </Form>
    </Container>;
}

const register = (client, values, navigate, setError) => {
  console.log('register ', values);
  client.mutate({
    mutation: REGISTER,
    variables: values,
    refetchQueries: ['Account']
  }).then(data => {
    if (data.data.register === null) {
      console.log('Registration failed');
    } else {
      navigate('/');
    }
  }).catch(e => {
    console.error("Unable to register", e)
    setError(e)
  });
}

export default Register;