import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Container, Dropdown, Form, Icon, Label } from 'semantic-ui-react';
import ModalText from './ModalText';

const ATTACHMENTS = gql`
query EditAttachments($id:  String) {
    observation(criteria: {anchor: $id}) {
        observations {
            id
            title
            contentType
            remoteContent
        }
        countAvailable
    }
}`
;

const ATTACH=gql`
  mutation Attach($anchor: String, $title: String, $source: String) {
    addObservation(observation: {
      title: $title,
      content: $source,
      remoteContent: true,
      anchor: $anchor,
      anchorRel: IMAGE,
      category: "Image",
      type: INFORMATION,
      status: DRAFT
      uri: $source
    }) {
      id
      title
      contentType
      remoteContent
    }
  }
`;

const DELETE=gql`
    mutation Delete($id: String!) {
        deleteObservation(id:$id)
    }
`;

const ReportAttachments = props => {
    const [attachments, setAttachments] = useState([])

    useQuery(ATTACHMENTS, {
        client: props.client,
        variables: {id: props.id},
        skip: !props.id,
        onCompleted: data => {
          console.log("Fetch Attachments", data?.observation)
          if (data?.observation) {
            setAttachments(data.observation.observations)
          }
        }
      });  

    return (
        <Container>
            <ul>
                {attachments.map((r, i) => {
                    return (<li key={i}>
                        {r.title} ({r.contentType}) {r.id}
                        <a href={process.env.REACT_APP_REST_URI + `/record/${r?.id}/content`} target='__blank'><Icon name='file outline' /></a>
                        <Icon 
                            className='link' 
                            style={{textDecoration: 'none'}} 
                            name='delete' 
                            onClick={e => 
                                props.client.mutate({
                                    mutation: DELETE,
                                    variables: {id: r.id},
                                    refetchQueries: ['Attachments', 'EditAttachments']
                                  }).then(data => {
                                    if (data.data.addObservation === null) {
                                      console.log('Attach failed');
                                    } else {
                                        setAttachments(attachments.filter(a => a.id !== r.id))
                                    }
                                  })
                            }
                            />
                    </li>)
                })}
            </ul>
            
            <AttachForm 
                client={props.client} 
                id={props.id}
                attachments={attachments}
                setAttachments={setAttachments}
                />
        
        </Container>
    )
}

const AttachForm = props => {
    const [title, setTitle] = useState("")
    const [source, setSource] = useState("")
    const [attachments, setAttachments] = [props.attachments, props.setAttachments]
    const [open, setOpen] = useState(false)
    const [activePane, setActivePane] = useState(0)

    const panes = [
        { menuItem: 'Web', render: () => <WebAttachment onChange={value => setSource(value)} /> },
        { menuItem: 'Upload', render: () => <UploadAttachment /> }
    ]

    const attach = () => {
        const attachMode = panes[activePane].menuItem
        console.log("Attach: " + attachMode)
        
        if (attachMode === "Web") {
            props.client.mutate({
                mutation: ATTACH,
                variables: {anchor: props.id, source: source, title: title},
                refetchQueries: ["Attachments", "EditAttachments"]
                }).then(data => {
                if (data.data.addObservation === null) {
                    console.log("Attach failed");
                } else {
                    setAttachments([data.data.addObservation].concat(attachments))
                    setOpen(false)
                }
                });
        } else if (attachMode === "Upload") {
            //Upload file
            const file = document.getElementById("attachFile").files[0]
            const data = new FormData()
            data.append("file", file)
            data.append("title", title)
            fetch(
                process.env.REACT_APP_REST_URI + `/record/${props?.id}/attach`,
                {
                    method: "POST",
                    credentials: "include",
                    body: data
                }
            ).then(response => response.json())
            .then(r => {
                setAttachments([r].concat(attachments))
                setOpen(false)
            })
            .catch(error => console.error(error))
        }
    }

    return (<ModalText text='Attach' open={open} setOpen={setOpen}>
            <Form onSubmit={attach}>
                    <Form.Field>
                        <Label>Title</Label>
                        <Form.Input placeholder='Title' value={title} onChange={e => setTitle(e.target.value)}/>
                    </Form.Field>
                    <Dropdown 
                        placeholder={panes[0].menuItem}
                        defaultValue={panes[0].menuItem}
                        fluid
                        selection
                        options={panes.map(p => {return {key: p.menuItem, text: p.menuItem, value: p.menuItem}})}
                        onChange={(e,d) => setActivePane(panes.map(p => p.menuItem === d.value).indexOf(true))}
                        />
                    
                    {
                        panes[activePane].render()
                    }
                    
                    <Button primary>Attach</Button>
                </Form>
            </ModalText>);
}

const WebAttachment = props => {
    const [source, setSource] = useState("")
    return (
        <Form.Field>
            <Form.Input 
                label='Link'
                placeholder='http://www.host.com/image.jpg' 
                value={source}
                onChange={e => setSource(e.target.value)}
                onBlur={e => props.onChange(source)}
            />
        </Form.Field>
    )
}

const UploadAttachment = props => {
    return (<Form.Field>
            <Form.Input 
                label='File'
                type='file'
                id='attachFile'
                />
        </Form.Field>)
}

export default ReportAttachments;