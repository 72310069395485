import React from 'react';
import { Card, CardGroup, Container } from 'semantic-ui-react';

const Home = props => {
  document.title = `navscout`;

  return (
    <Container>
      <h1>navscout</h1>
      <p style={{fontSize: '1.5em'}}>
      Get the information you need for your travels.
      </p>
      <CardGroup>
            <Card
                  image='/image/drive.jpeg'
                  header='Drive'
                  description='Get an assessment of the conditions for an upcoming trip.'
                  href='/drive/new'
            />
            <Card
                  image='/image/place.jpeg'
                  header='Places'
                  description='navscout provides travel assesments for cities across the globe.'
                  href='/place'
            />
            <Card
                  image='/image/chat.jpeg'
                  header='Chat'
                  description='Get live travel help from the navscout virtual assistant.'
                  href='/chat'
            />
      </CardGroup>
    </Container>
  )
}


export default Home;