import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Accordion, Button, Divider, Icon, Label, Message, Table } from 'semantic-ui-react';
import AdContent from './AdContent';
import AutoContent from './AutoContent';
import Extent from './Extent';
import Hideable from './Hideable';
import TimeUtil from './TimeUtil';
import Util from './Util';
import Gallery from './Gallery';
import SubjectContext from './SubjectContext';

const ObservationContent = props => {
    const navigate = useNavigate();

    const [showDetails, setShowDetails] = useState(false);
    var observation = props.observation;
    
    if (!observation) {
        return <Message icon>
            <Icon name='ban' />
            <Message.Content>
                <Message.Header>Content not available</Message.Header>
                The requested content is not available, it may have expired or been removed.
            </Message.Content>
        </Message>

    }
    const editable = (observation?.account?.id === observation.reporter || props?.account?.roles?.includes("POWER"));

    return <div id="content"> 
                <Gallery client={props.client} record={observation} />
                <Divider horizontal />
                <AutoContent
                    client={props.client}
                    content={observation?.content}
                    contentType={observation?.contentType}
                    />
                { observation?.tags?.length ? (<p>{observation.tags.map(tag => <Label color='blue'>{tag}</Label>)}</p>) : ""}
                <br/>
                { observation?.subjects?.map(s => <SubjectContext key={s.id} subject={s} />) }
                <br/>
                <AdContent id='ad' />
                <a class="nsbutton"
                    href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(observation.title)}&url=${encodeURIComponent(window.location.href)}`}
                    data-size="large"
                    target='__blank'
                    >Post on X</a>
                <Hideable hidden={!editable}>
                    &nbsp;
                    <Button icon='edit' onClick={() => navigate(`/report/edit/${observation.id}`)}/>
                </Hideable>
                <Accordion>
                    <Accordion.Title
                        onClick={(e, tp) => setShowDetails(!showDetails)}
                        ><Icon name='dropdown' />Details</Accordion.Title>
                    <Accordion.Content active={showDetails}>
                        <Details observation={observation} />
                    </Accordion.Content>
                </Accordion>  
            </div>
}

const Details = props => {
    const observation = props.observation;

    return (<Table>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell colSpan='2'>Details</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            <Table.Row>
                <Table.Cell>Occurred</Table.Cell>
                <Table.Cell>{TimeUtil.range(observation?.start, observation?.end)}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Impact</Table.Cell>
                <Table.Cell><Extent value={observation.extent} /></Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Assets</Table.Cell>
                <Table.Cell><ul>{observation?.affectedAssets?.map(a => <li key={a.id}><Link to={`/place/${a.id}`}>{a.label}</Link></li>)}</ul></Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Recorded</Table.Cell>
                <Table.Cell>{TimeUtil.longTime(observation?.created)}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Modified</Table.Cell>
                <Table.Cell>{TimeUtil.longTime(observation?.modified)}</Table.Cell>
            </Table.Row>
            <Hideable hidden={observation?.uri === null}>
                <Table.Row>
                    <Table.Cell>More Information</Table.Cell>
                    <Table.Cell><a target='_blank' rel='noopener noreferrer' href={observation?.uri}>{Util.hostname(observation?.uri)}</a></Table.Cell>
                </Table.Row>
            </Hideable>
        </Table.Body>
    </Table>)
}

export default ObservationContent;