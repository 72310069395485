import gql from 'graphql-tag';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, Container, Placeholder } from "semantic-ui-react";
import AdContent from './AdContent';
import InlineContent from './InlineContent';
import StaticMap from './StaticMap';
import { useQuery } from '@apollo/client';
import SubjectContext from './SubjectContext';

const PLACE_BY_ID = gql`
  query PlaceById($placeId: String!) {
      observation(criteria: {subjectId: $placeId, sensor: "com.navscout.main.assess.place.PlaceAssessmentReport"}) {
          countReturned
          countAvailable
          observations {
              id
              modified
              title
              category
              type
              start
              end
              contentType
              content
          } 
      }
      placeById(id: $placeId) {
        id
        label
        subscribed
        location {
            latitude
            longitude
        }
      }
  }`
  ;

const Place = props => {
    const params = useParams();

    const {loading, error, data} = useQuery(
        PLACE_BY_ID, 
        {client: props.client, 
            variables: {label: props.label, placeId: params.id},
            skip: !params.id && !props.label
        });


    if (loading) { return <Placeholder fluid>
            <Placeholder.Line length='full' />
            <Placeholder.Line length='full' />
            <Placeholder.Line length='full' />
            <Placeholder.Line length='full' />
            <Placeholder.Line length='full' />
        </Placeholder> 
    }

    if (error) {
        return "Error: " + error
    }

    if (!data || (!data.observation)) {
        return <span>You are not subscribed to any places, <a href='/resources'>subscribe to one</a>.</span>;
    }

    var obs = data.observation

    obs = ({
        ...obs,
        observations: obs.observations.map(o => ({
            ...o,
            content: (typeof(o?.content) === 'string' && o?.contentType?.endsWith("+json")) ? JSON.parse(o.content) : o.content
        }))
    })
    
    document.title = `navscout - ${data?.placeById?.label}`;

    return <Container>
        <Breadcrumb>
            <Breadcrumb.Section link><Link to='/place'>Places</Link></Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section active>{data?.placeById?.label || props.label}</Breadcrumb.Section>
        </Breadcrumb>
        <h1>
            <SubjectContext subject={{id: params.id, label: data?.placeById?.label, type: 'PLACE'}} />
        </h1>
        { data?.placeById?.location ? <StaticMap location={{point: data.placeById.location}} /> : null }
        <SubjectContext 
                subject={{id: params.id, label: data?.placeById?.label, type: 'PLACE'}} 
                open={true}
                exclude={['View']}
                />
        
        <br/>
        <AdContent id='ad' />
        <PlaceInfo
            observations={obs.observations}
            client={props.client}
            />
        <br/>
    </Container>
}

const PlaceInfo = props => {   
    if (!props.observations) {
        return null
    }

    var infoByCategory = new Map();

    props.observations.filter(i => i.type === "INFORMATION").forEach(i => {
        var info = infoByCategory.get(i.category);
        if (!info) {
            info = [];
            infoByCategory.set(i.category, info)
        }
        info.push(i);
    });

    return <div>        
        {Array.from(infoByCategory, ([k,v]) => {
            return <div key={k}>
                {/* <h2>{Util.capitalize(k)}</h2> */}
                {v.sort((a, b) => a.title.localeCompare(b.title)).map(o => 
                   <InlineContent observation={o} truncate={false} />
                )}
            </div>
        })}
    </div>
}

export default Place;