import React, { useState } from 'react';
import ObservationContent from './ObservationContent';
import InlineContent from './InlineContent';
import SubjectContext from './SubjectContext';
import Hideable from './Hideable';
import { Button, Icon } from 'semantic-ui-react';

const Record = props => {
    const [collapsed, setCollapsed] = useState(props.collapsed);

    return (<div>
        <h3>
            <SubjectContext account={props.account} subject={{ type: 'RECORD', id: props.record.id, label: props.record.title }} />
            <Icon name={collapsed ? 'expand' : 'compress'} link onClick={() => setCollapsed(!collapsed)} />
        </h3>
        <Hideable hidden={collapsed}>
        <InlineContent key={props.record.id} account={props.account} observation={props.record} client={props.client} />
        </Hideable>
    </div>)
}

const Records = props => {
    if (!props.records || props?.records?.length === 0) {
        return null
    }
    return (
        <div style={{paddingBottom: '1em'}}>
            <h2>{props.title}</h2>
            {props.records?.map(w => <Record record={w} collapsed={props.collapsed} account={props.account} client={props.client} />)}
        </div>
    )
}

export default Records;