import { ApolloClient, InMemoryCache, gql, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import { Dropdown, Menu } from 'semantic-ui-react';
import About from './About';
import Account from './Account';
import './App.css';
import Chat from './Chat';
import ErrorBoundary from './ErrorBoundary';
import Hideable from './Hideable';
import Home from "./Home";
import Images from './Images';
import Login from './Login';
import LoginGetToken from "./LoginGetToken";
import LoginToken from "./LoginToken";
import Place from "./Place";
import PlaceWeather from './PlaceWeather';
import Places from './Places';
import Purchase from './Purchase';
import Register from './Register';
import Report from "./Report";
import ReportEdit from './ReportEdit';
import Reports from './Reports';
import Resources from './Resources';
import Travel from "./Travel";
import TravelDetails from "./TravelDetails";
import TravelEdit from './TravelEdit';
import Travels from './Travels';
import NotificationDisplay from './NotificationDisplay';

const ACCOUNT = gql`
query Account {
  account {
    id
    preferredName
    purchase {
      status
    }
    roles
  }
}`;

const LOGOUT = gql`
mutation Logout {
  logout
}`;

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URI,
  cache: new InMemoryCache(),
  credentials: 'include'
});

const App = props => {
  const { data } = useQuery(ACCOUNT, { client: client });
  const account = data?.account;
  const [notifications, setNotifications] = useState([])

  const onError = e => setNotifications([...[e], ...notifications])

  console.log(`App version: ${process.env.REACT_APP_VERSION}`)

  return <ErrorBoundary>
    <div className='presentation'>
      <NotificationDisplay
        notifications={notifications}
        onClose={() => setNotifications([])}
        />
      <TopMenu account={account} client={client} />
      <div style={{ padding: '1em' }} className='main' id='main'>
        <Routes>
          <Route path='/about' element={<About client={client} />} />
          <Route path='/account' element={<Account client={client} />} />
          <Route path='/account/purchase' element={<Purchase client={client} />} />
          <Route path='/chat' element={<Chat client={client} />} />
          <Route path='/place' element={<Places account={account} client={client} onError={onError} />} />
          <Route path='/place/:id' element={<Place client={client} />} />
          <Route path='/place/:subjectId/chat' element={<Chat subjectType='PLACE' client={client} />} />
          <Route path='/place/:id/weather' element={<PlaceWeather account={account} client={client} />} />
          <Route path='/report' element={<Reports account={account} client={client} />} />
          <Route path='/report/add' element={<ReportEdit client={client} />} />
          <Route path='/report/edit/:id' element={<ReportEdit client={client} />} />
          <Route path='/report/:id' element={<Report account={account} client={client} />} />
          <Route path='/report/:subjectId/chat' element={<Chat subjectType='RECORD' client={client} />} />
          <Route path='/drive' element={<Travels client={client} />} />
          <Route path='/drive/new' element={<TravelEdit account={account} client={client} />} />
          <Route path='/drive/edit/:id' element={<TravelEdit account={account} client={client} />} />
          <Route path='/drive/:id' element={<Travel client={client} />} />
          <Route path='/drive/:id/details' element={<TravelDetails client={client} />} />
          <Route path='/drive/:subjectId/chat' element={<Chat subjectType='DRIVE' client={client} />} />
          <Route path='/gallery' element={<Images account={account} client={client} />} />
          <Route path='/resources' element={<Resources client={client} />} />
          <Route path='/login' element={<Login client={client} />} />
          <Route path='/login/get-token' element={<LoginGetToken client={client} />} />
          <Route path='/login/token' element={<LoginToken client={client} />} />
          <Route path='/register' element={<Register client={client} />} />
          <Route path='/' element={<Home client={client} />} />
        </Routes>
      </div>
    </div>
  </ErrorBoundary>
}


const TopMenu = props => {
  const [mobile, setMobile] = useState(window.innerWidth < 720)

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })


  const navigate = useNavigate();

  return <Menu secondary className='header'>
    <Menu.Item
      name={mobile ? '' : 'home'}
      icon='home'
      onClick={() => navigate('/')}
    />
    <Menu.Item
      name={mobile ? '' : 'drive'}
      icon='car'
      onClick={() => navigate('/drive')}
    />
    <Menu.Item
      name={mobile ? '' : 'places'}
      icon='map marker alternate'
      onClick={() => navigate('/place')}
    />
    <Menu.Item
      name={mobile ? '' : 'chat'}
      icon='chat'
      onClick={() => navigate('/chat')}
    />
    <Menu.Menu position='right'>
      <Dropdown item icon='grid layout'>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => navigate('/gallery')} icon='images outline' text='Gallery'></Dropdown.Item>
          <Dropdown.Item onClick={() => navigate('/report')} icon='file alternate outline' text='Reports'></Dropdown.Item>
          <Dropdown.Item onClick={() => navigate('/about')} icon='info' text='About'></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown item text={mobile ? '' : 'Account'} icon={props.account ? 'user' : 'user outline'}>
        <Dropdown.Menu>
          <Hideable hidden={!props.account}>
            <Dropdown.Item onClick={() => navigate('/account')}>Account</Dropdown.Item>
            <Dropdown.Item item text='Logout' onClick={e => {
              e.preventDefault();
              logout(props.client, navigate);
            }} />
          </Hideable>
          <Hideable hidden={props.account}>
            <Dropdown.Item item text='Login' onClick={() => navigate('/login')} />
            <Dropdown.Item item text='Register' onClick={() => navigate('/register')} />
          </Hideable>
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Menu>
  </Menu>
}

const logout = (client, navigate) => {
  client.mutate({
    mutation: LOGOUT,
    refetchQueries: ['Account', 'Alerts', 'FindPlace', 'GetCalendar', 'Places']
  }).then(data => {
    if (data.data.register === null) {
      console.log('Login failed');
    } else {
      console.log('logged out');
      navigate('/login');
    }
  });
}

export default App;
