import React from 'react';
import Assistant from './Assistant';
import { useParams } from 'react-router-dom';

const Chat = props => {
  document.title = `navscout`;
  const subjectType = props.subjectType;
  const { subjectId } = useParams()
   
  const subject = (subjectType && subjectId) ? {id: subjectId, type: subjectType} : null;

  return (
    <Assistant subject={subject} client={props.client} open={true}/>
  )
}


export default Chat;