import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import React, { useEffect } from 'react';

const QuantitativeTimeline = props => {
    const chartRef = React.useRef();

    useEffect(() => {
        var context = chartRef.current.getContext("2d");

        if (props.timeline) {
            loadChart(context, props.timeline);
        }
    })

    return <canvas id='chart' key={Math.random()} ref={chartRef} />;
}

const loadChart = (context, data) => {
    const labels = data.times.map(t => new Date(t))
    const values = data.values
console.log(data)
    new Chart(
        context,
        {
            type: 'line',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: `${data.label} (${data.unit || "no unit"})`,
                        data: values,
                        backgroundColor: '#ccc',
                        borderColor: '#aaa',
                        lineTension: 0
                    }
                ]
            },
            options: {
                animation: false,
                aspectRatio: window.innerWidth < 800 ? 1.5 : 2,
                title: {
                    display: true,
                    text: 'Forecast',
                },
                scales: {
                    x: {
                        type: 'time'
                    },
                    y: {
                        // ticks: {
                        //     min: 0,
                        //     max: 100
                        // }
                    }
                },
                plugins: {
                    tooltip: {
                      callbacks: {
                        label: (item, _data) => {
                            var unit = data.unit
                            if (unit) {
                                return `${item.raw} ${unit}`
                            } else {
                                return item.value;
                            }
                        }
                      }
                    }
                }
            }
        }
    );
}


export default QuantitativeTimeline;