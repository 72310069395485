import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import Dataset from './Dataset';

const QualitativeTimeline = props => {
    const chartRef = React.useRef();

    useEffect(() => {
      var context = chartRef.current.getContext("2d");

      if (props.timeline) {
        loadChart(context, props.timeline);
      }
  })
    
  return <canvas id='chart' key={Math.random()} ref={chartRef} />;
}

const loadChart = (context, data) => {
  const {times, values} = dedup(data.times, data.values.map(v => v === null ? '' : v));
  const labels = times.map(t => new Date(t))
  var yLabels = new Set();
  values.forEach(v => yLabels.add(v));
  yLabels=[...yLabels];

  Dataset.sortLevels(yLabels);

  new Chart(
      context,
      {
          type: 'line',
          data: {
              xLabels: labels,
              yLabels: yLabels,
              datasets: [
                  {
                      label: data.label,
                      data: values,
                      backgroundColor: '#ccc',
                      borderColor: '#aaa',
                      lineTension: 0
                  }
              ]
          },
          options: {
            animation: false,
              aspectRatio: window.innerWidth < 800 ? 1.5 : 2,
              title: {
                  display: true,
                  text: 'Forecast',
              },
              scales: {
                  x: {
                      type: 'time'
                  },
                  y: {
                      type: 'category'
                  }
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: (item, _data) => {
                        return item.raw;
                    }
                  }
                }
              }
          }
      }
  );
}

const dedup = (times, values) => {
  if (!times || times.length < 3) {
    return {times, values};
  }

  var lastValue = values[0];
  var lastTime = times[0];
  var deduped = {times: [times[0]], values: [values[0]]};

  for (var i = 1; i < values.length - 1; i++) {
    if (values[i] !== lastValue) {
      deduped.times.push(lastTime);
      deduped.values.push(lastValue);
      deduped.times.push(times[i]);
      deduped.values.push(values[i]);
      lastValue = values[i];
    }
    lastTime = times[i];
  }

  deduped.times.push(times[times.length - 1]);
  deduped.values.push(values[values.length - 1]);

  return deduped;
}

export default QualitativeTimeline;