import React, { useState } from 'react';
import { Button, Form, Icon, Label, Modal } from 'semantic-ui-react';
import Hideable from './Hideable';

const PersonEdit = props => {
  const [open, setOpen] = useState(false);
  const [fullName, setFullName] = useState(props.fullName || "");
  const [preferredName, setPreferredName] = useState(props.preferredName || "");
  const [email, setEmail] = useState(props.email || "");
  
  const close = () => { 
    setOpen(false); 
    if (props.clearOnClose) {
      setFullName("");
      setPreferredName("");
      setEmail("");
    }
  }

  return <Modal
    trigger={
      props.trigger ? <span onClick={() => setOpen(true)}>{props.trigger}</span>
        :
        <Icon style={{cursor:'pointer'}} name={props.icon} onClick={() => setOpen(true)}>{props.text}</Icon>
    }
    open={open}
    onClose={() => close()}
  >
    <Modal.Header>{props.header}</Modal.Header>
    <Modal.Content>
        <Form onSubmit={e => {
            e.preventDefault();
            props.onChange({fullName, preferredName, email});
            close();
          }}>
          <Form.Field>
            <Label>Full Name</Label>
            <Form.Input type="text" value={fullName} name="fullName" onChange={e => {e.preventDefault(); setFullName(e.target.value)}} />
          </Form.Field>
          <Form.Field>
            <Label>Preferred Name</Label>
            <Form.Input type="text" value={preferredName} name="preferredName" onChange={e => setPreferredName(e.target.value)} />
          </Form.Field>
          <Form.Field>
            <Label>email</Label>
            <Form.Input type="text" value={email} name="email" onChange={e => setEmail(e.target.value)} />
          </Form.Field>
          <Button>{props.submit||'Submit'}</Button>
          <Hideable hidden={props.onDelete ? false : true}>
            <Button onClick={() => {props.onDelete();close();} }>Delete</Button>
          </Hideable>
        </Form>
    </Modal.Content>
    </Modal>;
}

export default PersonEdit;