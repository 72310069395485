import React, { useState } from 'react';
import { Container, Select } from 'semantic-ui-react';
import QualitativeTimeline from './QualitativeTimeline';
import QuantitativeTimeline from './QuantitativeTimeline';

const MultiTimeline = props => {
    let [selection, setSelection] = useState(0);
    let [system, setSystem] = useState('imperial')

    const options = props?.dataSet?.labels?.filter((l, i) => i > 0)?.map((l, i) => {return {key: i, value: i, text: l} });
    return <Container>
            <Select 
                search
                options={options} 
                defaultValue={0}
                onChange={(e,d) => setSelection(d.value)}
                />
            <Select
                options={[{key: 'imperial', value: 'imperial', text: 'Imperial'},{key: 'metric', value: 'metric', text: 'Metric'}]}
                defaultValue='imperial'
                onChange={(e,d) => setSystem(d.value)}
                />
            { isQuantitative(props?.dataSet?.data[selection + 1]) ?
                quantitative(props?.dataSet, selection + 1, system) :
                qualitative(props?.dataSet, selection + 1)
            }
        </Container>
}

const isQuantitative = col => {
    if (!col || col.length === 0) {
        return true
    }
    for (var i = 0; i < col.length; i++) {
        var item = col[i];
        if (item !== null && typeof item !== 'number') {
            return false
        }
    }
    return true;
}

const quantitative = (ds, selection, system) => {
    const {values, unit} = convert(ds.data[selection], ds.units[selection], system)

    var content = {
        times: ds.data[0],
        values: values,
        unit: unit,
        label: ds.labels[selection]
    };

    return <QuantitativeTimeline 
                    timeline={content}
                    min={content.start}
                    max={content.end} />
}

const CONVERTERS = {
    "imperial" : {
        "celsius" : v => [(v * 9.0 / 5.0) + 32, "fahrenheit"],
        "millimeter": v => [v / 25.4, "inch"],
        "centimeter": v => [v / 2.54, "inch"],
        "meter" : v => [v / .3048, "foot"],
        "kilometer": v => [v / 1.609, "mile"],
        "meter per hour" : v => [v / .3048, "foot per hour"],
        "kilometer per hour": v => [v / 1.609, "mile per hour"]
    },
    "metric" : {
        "fahrenheit" : v => [(v - 32) * 5.0 / 9.0, "celsius"],
        "inch": v => [v * 2.54, "centimeter"],
        "foot" : v => [v * .3048, "meter"],
        "mile": v => [v * 1.609, "kilometer"],
        "foot per hour" : v => [v * .3048, "meter per hour"],
        "mile per hour": v => [v * 1.609, "kilometer per hour"],
    }
}

const convert = (values, unit, system) => {
    var converter = CONVERTERS[system]?.[unit]
    if (converter) {
        values = values.map(v => converter(v)[0])
        unit = converter(0)[1]
    }
    return {values, unit}
}

const qualitative = (ds, selection) => {
    var content = {
        start: ds.data[0][0],
        end: ds.data[0][ds.data.length - 1],
        times: ds.data[0],
        values: ds.data[selection],
        unit: ds.units[selection],
        label: ds.labels[selection]
    };

    return <QualitativeTimeline 
                    timeline={content}
                    min={content.start}
                    max={content.end} />
}

export default MultiTimeline;