import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useEffect } from 'react';
import { Container, Item } from 'semantic-ui-react';

const PRODUCTS = gql`
query {
  availableProducts {
    processor
    id
    label
    pricingOptions {
      planId
      cost
      currency
      billing
      trialDays
    }
    maxPeople
    maxPlaces
    maxQueries
    maxTravels
  }
}
`
;

const INITIATE_PURCHASE = gql`
mutation InitiatePurchase($planId:String!) {
  initiatePurchase(planId:$planId)
}`;

const useScript = url => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};

const Purchase = props => {
  const {loading, error, data} = useQuery(PRODUCTS, {
    client: props.client
  });

  useScript('https://js.stripe.com/v3/');
  
  if (loading) {
    return "Loading..."
  }

  document.title = 'navscout - Purchase a plan';

  return <Container>
      <h3>Select a Plan</h3>
      <p>All plans come with a 7 day trial period.</p>
      <p>Select a plan to checkout with stripe.</p>
      <Item.Group>
        {
          data.availableProducts.map(p => 
            <Item key={p.id}>
              <Item.Content>
                <Item.Header>{p.label}</Item.Header>
                <Item.Meta>
                  Monitored places: <b>{p.maxPlaces}</b><br/>
                  Monitored trips: <b>{p.maxTravels}</b><br/>
                  People: <b>{p.maxPeople}</b><br/>
                  {/* Monthly location reports: <b>{p.maxQueries}</b><br/> */}
                </Item.Meta>
                  <Item.Extra>
                  {p.pricingOptions.map(pp => 
                      <button className='nsbutton' onClick={() => checkout(props.client, pp.planId)}>
                          ${pp.cost/100} {pp.currency.toUpperCase()} {period(pp.billing)}
                      </button>
                  )}
                </Item.Extra>
              </Item.Content>
            </Item>
          )
        }
      </Item.Group>
    </Container>;
}

const period = p => {
  return {
    "P1D" : "Daily",
    "P1W" : "Weekly",
    "P1M" : "Monthly",
    "P1Y" : "Yearly"
  }[p]
}

const checkout = (client, planId) => {
  console.log("Checkout "  + planId);
  client.mutate({
    mutation: INITIATE_PURCHASE,
    variables: {planId: planId}
  }).then(data => {
    if (data.data.initiatePurchase === null) {
      console.log('Initiate purchase failed');
    } else {
      const sessionId = data.data.initiatePurchase;
      console.log('Session: ' + sessionId);
      var stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      stripe.redirectToCheckout({
        sessionId: sessionId
      }).then(result => {
        console.log(result.error.message);
      });
    }
  });
}

export default Purchase;