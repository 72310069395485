import React, { useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import GeoMap from "./GeoMap";
import Colors from "./Colors";
import gql from 'graphql-tag';
import SubjectContext from './SubjectContext';

const PLACES = gql`
  query Places($label:String,$bbox:[Float]) {
    account {
      id
    }
    place(criteria: {label:$label,includePublic:true,bbox:$bbox}) {
      places {
        id
        label
        location {
          countryCode
          locality
          latitude
          longitude
        }
        alertCount
      }
    }
  }`;

const PlaceMap = props => {
    const label = props.searchValue
    const {loading, error, data} = useQuery(PLACES, {client: props.client, variables: {label}});
    const [place, setPlace] = useState(null);
    const placeData = useRef(null)
    const allPlaces = useRef([])
  
    if (loading) {
      return 'Loading...';
    }

    const places = data?.place?.places;

    return <div style={{height: '100%'}}>
        <GeoMap 
          searchValue={label}
          activePlace={place}
          renderContent={true}
          client={props.client}
          onSearchChange={props.onSearchChange}
          onClick={id => {
            setPlace({id});
          }}
          loadData={(l, bbox, cb) => {
            props.client.query({
              query: PLACES,
              variables: l ? { label: l } : { bbox }
            }).then(data => {
              if (!data?.data?.place) {
                console.log('Load places failed');
              } else {
                var places = data?.data?.place?.places?.map(p => {
                    return {
                      id: p.id,
                      type: "Place",
                      color: p.alertCount === 0 ? Colors.GREEN : p.alertCount === 1 ? Colors.BLUE : Colors.RED,
                      geometry: {
                        type: "Point",
                        coordinates: [p.location.longitude, p.location.latitude]
                      }
                    };
                });
                if (placeData.current !== null && places?.filter(i => i.id === placeData.current.id)?.[0] === undefined) {
                  places.push(placeData.current)
                }
                allPlaces.current = places
                cb(places, l ? true : false);
              }
            }).catch(err => {
              console.log(err.message);
            })
          }}
          clearContent={() => {
            placeData.current = null
          }}
          loadContent={(id, cb) => {
            placeData.current = allPlaces.current?.filter(i => i.id === id)?.[0];
            cb(
              <div>
                <h3>{places?.filter(p => p.id === id)[0]?.label}</h3>
                <SubjectContext 
                subject={{id: id, label: places?.filter(p => p.id === id)[0]?.label, type: 'PLACE'}} 
                open={true}
                exclude={['Reports', 'Gallery', 'Post on X']}
                />
              </div>
          )
          }}
          />
    </div>
}

export default PlaceMap;