import React from 'react';
import { Container, Icon, Table } from 'semantic-ui-react';
import Dataset from './Dataset';
import DatasetRisk from './DatasetRisk';
import MultiTimeline from './MultiTimeline';
import GeoMap from './GeoMap';
import QualitativeTimeline from './QualitativeTimeline';
import QuantitativeTimeline from './QuantitativeTimeline';
import Util from './Util';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const AutoContent = props => {
    var content = props.content;
    
    if (typeof content === 'string' && props.contentType.endsWith('+json')) {
        content = JSON.parse(content);
    }

    if (props.contentType === 'application/vnd.time-series-qualitative+json') {
        return <Container>
                <QualitativeTimeline
                timeline={content}
                min={content.start}
                max={content.end}
                />
            </Container>
    } else if (props.contentType === 'application/vnd.time-series-quantitative+json') {
        return <Container>
                <QuantitativeTimeline
                timeline={content}
                min={content.start}
                max={content.end}
                />
            </Container>
    } else if (props.contentType === 'application/vnd.dataset+json') {
        var renderer = Dataset.renderer(content);

        if (renderer === 'TIME_MULTI') {
            return <Container>
                <MultiTimeline 
                    dataSet={content}
                    />
            </Container>
        } else if (renderer === 'RISK') {
            return <Container>
                <DatasetRisk
                    dataset={content}
                    chartType="bar"
                    x="Factor"
                    y="Order"
                    label="Risk"
                    yAxisLabels={[
                        "Insufficient Data", "Not Applicable", "No Rating", "Very Low", "Low",
                        "Relatively Low", "Relatively Moderate", "Relatively High", "High"
                    ]}
                />
            </Container>
        } else if (renderer === 'MAP') {
            var route = [];
            const data = content.data;

            for (var i = 0; i < data[0].length; i++) {
                route.push({latitude: parseFloat(data[2][i]), longitude: parseFloat(data[3][i])});
            }

            return <GeoMap 
                client={props.client}
                loadData={(_, cb) => {
                    cb([{
                        geometry: {
                            type: "LineString",
                            coordinates: route.map(c => [c.longitude, c.latitude])
                        }
                    }])
                }}
                />
        } else if (renderer === 'TABLE') {
            return <Icon name='table' />
        } else if (renderer === 'VALUE') {
            var value = content?.data[0][0]
            if (typeof(value) == 'number') {
                value = value.toFixed(1);
            }
            return `${value} ${content?.units[0]}`;
        } else if (renderer === 'MULTI_VALUE') {
            return <Table definition>
                <Table.Body>
                    {content?.data?.map((d, i) => (<Table.Row>
                        <Table.Cell>{content.labels[i]}</Table.Cell>
                        <Table.Cell>{d}{content?.units ? ` ${content.units[i]}` : ""}</Table.Cell>
                    </Table.Row>)
                    )}
                </Table.Body>
            </Table>;
        } else {
            return Util.capitalize(renderer);
        }
    } else if (props.contentType === 'text/markdown') {
        return <div><ReactMarkdown>
            {props.content}
        </ReactMarkdown></div>
    } else if (props.contentType === 'text/html') {
        return <div dangerouslySetInnerHTML={{__html: props.content}} />
    }

    return <p>{props.content}</p>;
}

export default AutoContent;