import React from 'react';
import moment from 'moment';
import Dataset from './Dataset';
import { Icon } from 'semantic-ui-react';
import Util from './Util';
import { Link } from 'react-router-dom';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import Hideable from './Hideable';

const MAX_TEXT_LENGTH = 600;
const MARKDOWN_LINE_LIMIT = 20;

const InlineContent = props => {
    var truncate = (props.truncate === undefined || props.truncate === null || props.truncate === true) ? true : false;
    var observation = props.observation;
    if (observation.contentType === 'application/vnd.time-series-qualitative+json') {
        var summary = qualValueAtTime(observation.content, new Date());
        if (summary.next) {
            return <span>{summary.current} ({summary.next})</span>
        } else {
            return <span>{summary.current}</span>
        }
    } else if (observation.contentType === 'application/vnd.time-series-quantitative+json') {
        var summary = quantValueAtTime(observation.content, new Date());
        if (summary) {
            return <span><span style={{fontSize: '1.2em'}}>{summary.current}</span> ({summary.min}-{summary.max})</span>
        }
    } else if (observation.contentType === 'application/vnd.dataset+json') {
        var ds = observation?.content?.data;
        var labels = observation?.content?.labels;
        var renderer = Dataset.renderer(observation?.content);

        if (renderer === 'TIME_MULTI') {
            return <Link to={`/report/${observation?.id}`}><Icon name="line graph" /></Link>
        } else if (renderer === 'RISK') {
            return `${ds[0][0]}: ${ds[1][0]}`
        } else if (renderer === 'MAP') {
            return <Link to={`/report/${observation?.id}`}><Icon name='map' /></Link>
        } else if (renderer === 'TABLE') {
            return <Link to={`/report/${observation?.id}`}><Icon name='table' /></Link>
        } else if (renderer === 'VALUE') {
            var value = ds[0][0]
            if (typeof(value) == 'number') {
                value = value.toFixed(1);
            }
            return `${value} ${observation?.content?.units[0]}`;
        } else if (renderer === 'MULTI_VALUE') {
            var out = "";

            for (var col = 0; col < Math.min(3, ds.length); col++) {
                if (col > 0) {
                    out += ", ";
                }
                out += `${labels[col]}: ${ds[col][0]}`
            }

            if (ds.length > 3) {
                out += "...";
            }

            return out;
        } else {
            return Util.capitalize(renderer);
        }
    } else if (observation.contentType === 'text/markdown') {
        return <div>
            <ReactMarkdown>
                {truncate ? observation.content?.split("\n").slice(0,MARKDOWN_LINE_LIMIT).join("\n") : observation?.content}
            </ReactMarkdown>
            </div>
    } else if (observation.contentType === 'text/plain') {
        if (observation.content) {
            if (observation.content.length > MAX_TEXT_LENGTH) {
                return (<span>
                        {truncate ? (observation.content.substr(0, MAX_TEXT_LENGTH) + "...") : observation?.content}
                        </span>)
            }
            return observation.content;
        }
    }  else if (observation.contentType === 'text/html') {
        return <Link to={`/report/${observation?.id}`}><Icon name='file outline' /></Link>
    } else if (observation.contentType?.startsWith("image/")) {
        if (observation.remoteContent) {
            return <img src={observation.content} style={{maxWidth:'100%'}} />
        } else {
            return  <img src={`data:image/png;base64, ${observation.content}`} style={{maxWidth:'100%'}} />
        }
    }

    return null;
}

const qualValueAtTime = (ts, t) => {
    const tm = moment(t);
    const sm = moment(ts.start);
    const em = moment(ts.end);

    if (tm.isBefore(sm) || tm.isAfter(em)) {
        return {current: ts?.values?.slice(0, 3)?.filter((i,ix) => i !== ts.values[ix-1])?.map(c => (c || "").substring(0, 20) + (c?.length > 20 ? "..." : "")) + (ts?.values.length > 3 ? '...' : '')};
    }

    const summary = {current: null, next: null};

    const prior = search(ts.times, 0, ts.times.length - 1, t);

    if (prior !== -1) {
        summary.current = ts.values[prior] || "None";
        if (prior < ts.times.length - 1) {
            const nextTime = moment(ts.times[prior + 1]);
            const nextValue = ts.values[prior + 1] || "None";
            summary.next = `${nextValue} ${tm.to(nextTime)}`;
        }
    }

    return summary;
}

const quantValueAtTime = (ts, t) => {
    const tm = moment(t);
    const sm = moment(ts.start);
    const em = moment(ts.end);

    if (tm.isBefore(sm) || tm.isAfter(em)) {
        return  {
            min: (ts?.values?.reduce((a, b) => Math.min(a, b)))?.toFixed(2),
            max: (ts?.values?.reduce((a, b) => Math.max(a, b)))?.toFixed(2)
        };
    }

    const prior = search(ts.times, 0, ts.times.length - 1, t);
    var summary = {current: null, min: null, max: null};

    if (prior === -1) {
        return null;
    } else {
        if (ts.unit) {
            summary.current = `${ts.values[prior]} ${ts.unit}`
        } else {
            summary.current = ts.values[prior];
        }
    }

    var dayStart = moment(tm).startOf('day')
    var dayEnd = moment(tm).endOf('day')

    for (var i = 0; i < ts.times.length; i++) {
        var im = moment(ts.times[i]);
        if (im.isBefore(dayStart)) {
            continue;
        }
        if (im.isAfter(dayEnd)) {
            break;
        }
        if (!summary.min || ts.values[i] < summary.min) {
            summary.min = ts.values[i];
        }
        if (!summary.max || ts.values[i] > summary.max) {
            summary.max = ts.values[i];
        }
    }

    return summary;
}

const search = (entries, lo, hi, t) => {
    if (lo > hi) {
        return -1;
    }

    const mid = lo + Math.round((hi - lo) / 2);
    const midTime = moment(entries[mid]);
    const mt = moment(t);

    if (mt.isBefore(midTime)) {
        return search(entries, lo, mid - 1, t);
    } else if (mt.isSame(midTime)) {
        return mid;
    } else {
        if (    (mid === entries.length - 1)
                    || (mid < entries.length - 1 && mt.isBefore(moment(entries[mid + 1])))) {
                return mid;
            }
            return search(entries, mid + 1, hi, t);
    }
}

export default InlineContent;