import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container, Divider, Form } from 'semantic-ui-react';
import Validate from './Validate';

const GET_TOKEN = gql`
mutation GetToken($email:String!) {
  requestPasswordReset(email:$email)
}`
;

const LoginGetToken = props => {
  const [email, setEmail] = useState('');
  let navigate = useNavigate();

  document.title = `navscout - Login`;

  return <Container>
      <h3>Enter your email, a one time login token will be sent to your email.</h3>
      <Form fluid="true" onSubmit={e => {
          e.preventDefault();
          getToken(props.client, email, navigate);
        }} >
        <Form.Field>
          <label>Email</label>
          <input name='email' placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} />
        </Form.Field>
        <Divider hidden />
        <Container textAlign='center'>
          <Button
              disabled={!Validate.notBlank(email)}
              className='nsbutton' 
              style={{margin:'0 auto 0 auto'}}
              primary="true"
              >Get Token</Button>
          <Divider hidden />
          <Link to='/login'>Login</Link>
          &nbsp;&bull;&nbsp;
          <Link to='/login/get-token'>Register</Link>
        </Container>
      </Form>
    </Container>;
}

const getToken = (client, email, navigate) => {
  client.mutate({
    mutation: GET_TOKEN,
    variables: {email},
    refetchQueries: ['Account', 'Alerts', 'FindPlace', 'GetCalendar', 'Places']
  }).then(data => {
    if (!data.data.requestPasswordReset === null) {
      console.log('Request token failed');
    } else {
      navigate('/login/token');
    }
  });
}

export default LoginGetToken;