import gql from 'graphql-tag';
import React from 'react';
import { Table } from 'semantic-ui-react';
import PersonEdit from './PersonEdit';

const ADD_PERSON = gql`
mutation AddPerson($email:String,$fullName:String,$preferredName:String) {
  addPerson(person: {email:$email,fullName:$fullName,preferredName:$preferredName}) {
    id
  }
}`;

const UPDATE_PERSON = gql`
mutation UpdatePerson($id:String!,$email:String,$fullName:String,$preferredName:String) {
  updatePerson(id:$id, person: {email:$email,fullName:$fullName,preferredName:$preferredName}) {
    id
  }
}`;

const DELETE_PERSON = gql`
mutation DeletPerson($id:String!) {
  deletePerson(id:$id)
}`;

const People = props => {
    const data = props.data;
    
    return <div>
        <h4>
        People
        &nbsp;
        <PersonEdit
          header='Add Person'
          submit='Add'
          icon='add'
          values={[
            {label: 'Full Name', key: 'fullName'},
            {label: 'Preferred Name', key: 'preferredName'},
            {label: 'Email', key: 'email'}
          ]}
          onChange={values => addPerson(props.client, values)} 
          clearOnClose={true}
          />
      </h4>
      
      <Table celled>
          <Table.Header>
              <Table.Row>
                <Table.Cell>Name</Table.Cell>
              </Table.Row>
          </Table.Header>
        {data?.account?.people.map((p,i) => {
          return <Table.Row key={i}>
            <Table.Cell>
            <PersonEdit
                header='Edit Person'
                submit='Save'
                trigger={<span className='link'>{p.fullName}</span>}
                fullName={p.fullName}
                preferredName={p.preferredName}
                email={p.email}
                onChange={values => editPerson(props.client, p.id, values)}
                onDelete={() => deletePerson(props.client, p.id)}
                clearOnClose={false}
                />
            </Table.Cell>
            </Table.Row>
        })}
      </Table>
    </div>
}

const addPerson = (client, values) => {
    console.log('Add person ', values);
    client.mutate({
      mutation: ADD_PERSON,
      variables: values,
      refetchQueries: ['Resources']
    }).then(data => {
      if (data.data.addPerson === null) {
        console.log('Add person failed');
      }
    });
  }
  
const editPerson = (client, id, values) => {
  console.log('Edit person ', values);
  client.mutate({
    mutation: UPDATE_PERSON,
    variables: {...values, id},
    refetchQueries: ['Resources']
  }).then(data => {
    if (data.data.updatePerson === null) {
      console.log('Update person failed');
    }
  });
}
  
const deletePerson = (client, id) => {
  console.log('Delete person ', id);
  client.mutate({
    mutation: DELETE_PERSON,
    variables: {id},
    refetchQueries: ['Resources']
  }).then(data => {
    if (data.data.deletePerson === null) {
      console.log('Delete person failed');
    }
  });
}

export default People;