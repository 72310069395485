import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container, Header, Icon, Popup, Table } from 'semantic-ui-react';
import Hideable from './Hideable';
import RefreshButton from './RefreshButton';
import TravelPeople from './TravelPeople';
import Util from './Util';
import SubjectContext from './SubjectContext';

const GET_TRAVEL = gql`
query TravelInformation($range: String!){
    listTravelInformation(range: $range) {
      id
      departure
      label
      alertCount
      status
      assignments {
        task
        person {
          id
          fullName
        }
      }
    }
  }`
;

const Travels = props => {
  const navigate = useNavigate();
  const {loading, error, data} = useQuery(GET_TRAVEL, {client: props.client, variables: {range: "PT-4H/P30D"}});
  
  if (!Util.hasSession()) {
    return <Container>
              <Header as='h2'>
              Discover what lies ahead.
              </Header>
              <p style={{'fontSize':'large'}}>
              navscout can monitor weather conditions on your travel route and provide advance notifications of hazards on your path.
              </p>
              <p style={{'fontSize':'large'}}>
              In order to get a travel report you must <a href='/register'>register</a> or <a href='/login'>login</a>.
              An account is free to open and you can subscribe to gain additional capability.
              </p>
            </Container>
  }

  if (!data) {
    return "Loading...";
  }

  document.title = `navscout - Travel`;

  return <div>
      <Button onClick={() => navigate('/drive/new')}>Check drive conditions</Button>
      <span style={{'paddingLeft':'1em'}} />
      <RefreshButton client={props.client} queries={['TravelInformation']} />
      <br/>
      <h2>Your trips</h2>
      <Table celled>
          <Table.Header>
              <Table.Row>
                <Table.Cell>Status</Table.Cell>
                <Table.Cell>Date</Table.Cell>
                <Table.Cell>Label</Table.Cell>
                <Table.Cell>People</Table.Cell>
              </Table.Row>
          </Table.Header>
        {data.listTravelInformation.map((travel, i) => 
          <Table.Row key={i} error={travel.alertCount > 0}>
            <Table.Cell>
              {Util.capitalize(travel.status)}
              <Hideable hidden={travel.alertCount === 0}>
                &nbsp;
                <Popup 
                  content={`${travel.alertCount} alerts`}
                  trigger={<Icon name='warning sign' aria-label={`${travel.alertCount} alerts`} />}
                  />
              </Hideable>
            </Table.Cell>
            <Table.Cell>
              {moment(travel.departure).format("MMMM DD h:mm A")}
            </Table.Cell>
            <Table.Cell>
            <SubjectContext subject={{type: 'TRAVEL', id: travel.id, label: travel.label}} />
            &nbsp;&nbsp;
            <Link to={`/drive/edit/${travel.id}`}><Icon name='edit' /></Link>
            </Table.Cell>
            <Table.Cell>
              <TravelPeople client={props.client} travel={travel.id} text={travel.assignments?.length || 0}
              action="Add" />
            </Table.Cell>
            </Table.Row>
        )}
      </Table>
    </div>;
}

export default Travels;