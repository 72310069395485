import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Container, Header, Icon } from 'semantic-ui-react';
import './App.css';
import { Link } from 'react-router-dom';

const About = props => {
    return (
        <Container fluid>
            <Header>About</Header>
            Version: {process.env.REACT_APP_VERSION || 'Unknown'}
            &nbsp;
            <a href='javascript:window.location.reload()'>Update</a>
            <br/>
            <Header>Social</Header>
            <Link style={{textDecoration: 'none'}} to='https://www.twitter.com/navscout/'><Icon size='big' name='twitter' /></Link>
            <Link style={{textDecoration: 'none'}} to='https://www.instagram.com/trynavscout/'><Icon size='big' name='instagram' /></Link>
            <Link style={{textDecoration: 'none'}} to='https://www.facebook.com/navscout/'><Icon size='big' name='facebook' /></Link>
        </Container>
    )
}

export default About;