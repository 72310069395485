import React, { useRef, useState } from 'react';
import gql from 'graphql-tag';
import 'semantic-ui-css/semantic.min.css';
import { Button, Container, Divider, Feed, FeedContent, FeedDate, FeedEvent, FeedExtra, FeedLabel, FeedSummary, FeedUser, Form, Header, Icon, Image, Input, Label } from 'semantic-ui-react';
import './App.css';
import Util from './Util';
import TimeUtil from './TimeUtil';
import InlineContent from './InlineContent';
import SubjectContext from './SubjectContext';
import { useQuery } from '@apollo/client';
import Hideable from './Hideable';

const USER_LOCATION_COOKIE = "USER_LOCATION"

const EVALUATE_PROMPT = gql`
mutation EvaluatePrompt($subject: String, $message:String!) {
  speak(subject: $subject, message:$message) {
    id
    created
    updated
    content {
      participantType
      content
      contentType
      remoteContent
      subjects {
        id
        type
        label
      }
      time
    }
  }
}
`;

const GET_CONVERSATION = gql`
query Conversation($subject:String) {
  conversation(subject:$subject) {
    id
    created
    content {
      participantType
      content
      contentType
      remoteContent
      subjects {
        id
        type
        label
      }
      time
    }
    account
  }
  getSubject(id:$subject) {
    type
    label
    id
  }
}
`;

const GET_LOCATION = gql`
  query GetLocation {
    geolocate {
      location {
        label,
        country,
        adminDistrict,
        adminDistrict2,
        locality
      }
    }
  }
`;

const Dialog = props => {
  var events = props?.conversation?.content;
  let subjects = new Map()

  if(!events) {
    return null
  }

  events.forEach(e => {
    e.subjects.forEach(s => {
      if (s.id && !subjects.has(s.id)) {
        subjects.set(s.id, s)
      }
    });
  });

  events = events.map(event => {
    return (
        <FeedEvent 
          style={{flex: '1 1 auto'}}
          >
            <FeedLabel>
              {
                event.participantType === 'USER' ?
                  <Icon color='blue' name='user circle' /> :
                  event.participantType === 'AD' ?
                  <Icon color='gray' name='audio description' /> :
                  <Image src='/image/chat.jpeg' />
              }
            </FeedLabel>
            <FeedContent>
              <FeedSummary>
                <FeedUser>{event.participantType === 'USER' ? 'You' : event.participantType === 'AD' ? 'Ad' : 'navscout'}</FeedUser>
                <FeedDate>{TimeUtil.shortTime(event.time)}</FeedDate>
              </FeedSummary>
              <FeedExtra>
                <InlineContent observation={event} />
              </FeedExtra>
            </FeedContent>
        </FeedEvent>
      )
  });

  subjects = Array.from(subjects.values())
  
  return (
    <Container>
      <div id='dialogWrapper'>
        <Feed id='dialog'>
          {events}
        </Feed>
      </div>
    {subjects.map(s => <SubjectContext subject={s} />)}
    </Container>
  )
}

const queryEvent = query => {
  return (
    {
      key: Math.random(),
      date: TimeUtil.dateTime(new Date()),
      sender: 'user',
      extraText: (
        <div>
          <p>
            {query}
          </p>
        </div>
      )
    }
  )
}

const messageEvent = (type, message) => {
  return (
    {
      key: Math.random(),
      date: TimeUtil.dateTime(new Date()),
      summary: Util.capitalize(type),
      sender: 'system',
      extraText: (
        <div>
          <p>
            {message}
          </p>
        </div>
      )
    }
  )
}

const contentEvent = data => {
  return (
    {
      key: Math.random(),
      date: TimeUtil.dateTime(new Date()),
      summary: data.title,
      sender: 'system',
      extraText: (
        <InlineContent observation={data} />
      )
    }
  )
}

const SAMPLE_PROMPTS = [
  "What is the weather for a drive from Philadelphia to Pittsburgh?",
  "Will it rain today?",
  "What is the time in Paris?",
  "Show a picture of Seattle",
  "Show Japan on a map",
  "What is in the news in the United States?"
]

const DefaultContent = props => {
  return (
    <p>Get live travel help from the navscout virtual assistant.</p>
  )
}

const Assistant = props => {
  const inputReference = useRef(null)
  const [inputValue, setInputValue] = useState('')
  const [events, setEvents] = useState([])
  const [conversation, setConversation] = useState(null)
  const [subject, setSubject] = useState(null)

  const appendEvents = (events, newEvents) => {
    setEvents(events.concat(newEvents))
  }

  useQuery(GET_CONVERSATION, {
    client: props.client,
    variables: { rnd: Math.floor(new Date().getTime() / 2000), subject: props?.subject?.id },
    skip: !Util.hasSession(),
    pollInterval: 2000,
    onCompleted: d => {
      if (d?.conversation) {
        if (!Util.equals(conversation, d.conversation)) {
          console.log("Conversation update", props.subject?.id)
          setConversation(d.conversation)
        } else {
          console.debug("Conversation not updated")
        }
      } else {
        setConversation(null)
        setSubject(null)
      }
      if (d.getSubject) {
        setSubject(d.getSubject)
      }
    }
  });

  const query = q => {
    appendEvents(events, [queryEvent(q)])
    props.client.mutate({
      mutation: EVALUATE_PROMPT,
      variables: { message: q , subject: props?.subject?.id},
      refetchQueries: []
    }).then(data => {
      if (data?.data?.speak) {
        setConversation(data.data.speak)
      }
    }).catch(err => {
      console.error(err)
    });
  }

  //It is necessary to store this as a global so that defaultEvent can access it
  //If it is passed, it cannot access the value of events
  window.query = query;

  const submitQuery = () => {  
    query(inputValue)
    setInputValue('')
  }

  return (
    <div className='assistant' style={{height:"100%"}} >
      <Hideable hidden={props.subject}>
        <Locator client={props.client} />
      </Hideable>
      {subject && (
        <h3>Discuss {subject.label}</h3>
      )}
      <Hideable hidden={props.subject || events?.length > 0}>
        <DefaultContent client={props.client} />
      </Hideable>
      {/* Dialog */}
      <Dialog subject={props.subject} conversation={conversation} events={events} style={{flex: '1 1 0%'}} />
      <Divider horizontal />
      {/* Query Box */}
      <div id='query'>
      <Form onSubmit={submitQuery} className='footer'>
          <Form.Field>
            <Form.TextArea
              size='big'
              id='nsQuery'
              ref={inputReference}
              value={inputValue}
              onChange={e => { e.preventDefault(); setInputValue(e.target.value) }}
              placeholder='Ask a question...'
              icon={{ name: 'send', circular: true, link: true, onClick: submitQuery }}
            />
          </Form.Field>
          <Form.Button onClick={submitQuery} size='large' color='blue'>Send</Form.Button>
          <Hideable hidden={events?.length > 0}>
          <Label>Suggestions</Label>
            <div style={{height: '5em', overflowX: 'scroll', overflowY: 'hidden', whiteSpace: 'nowrap', marginTop: '1em', padding: '1em', border: '1px solid lightblue'}}>
              {SAMPLE_PROMPTS.map((prompt, key) => {
                return <Button style={{display: 'inline'}} key={key} onClick={e => {
                  setInputValue(e.target.innerText)
                }}>{prompt}</Button>
              })}
            </div>
          </Hideable>
        </Form>
      </div>
    </div>
  )
}

const Locator = props => {
  const [location, setLocation] = useState(Util.parseJson(document.cookie[USER_LOCATION_COOKIE]));

  useQuery(GET_LOCATION, {
    client: props.client,
    skip: location,
    variables: {},
    onCompleted: d => {
      const geolocation = d.geolocate.location;
      const jsonLocation = JSON.stringify(geolocation)
      const b64Location = Buffer.from(jsonLocation).toString("base64")
      Util.setCookie(USER_LOCATION_COOKIE, b64Location, 60 * 60);
      setLocation(geolocation)
    }
  });
  return (
    <Container id='locator' style={{border: '1px solid black', borderRadius: '0.5em', padding: '.5em',  marginBottom: '.5em'}}>
      <Icon title='Location' name='location arrow' />{location?.label || 'Locating'}
    </Container>
  )
}

export default Assistant;